import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import Seo from '../../components/seo';
import messages from '../../views/Login/messages';
import Main from '../../components/Main';
import { Form, Formik } from 'formik';
import Card from '../../components/Card';
import StepTitle from '../../components/StepTitle';
import Alert from '../../components/Alert';
import Input from '../../components/fields/Input';
import Button from '../../components/Button';
import Container from '../../components/Container';
import { getResponseErrors, jsonApiAxios } from '../../services/api';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
});

const ForgotPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);

  const { formatMessage } = useIntl();

  function handleSubmit(values, { setSubmitting }) {
    setErrors(false);
    setSuccess(false);
    setIsSubmitting(true);
    jsonApiAxios
      .post('/passwordResets', values, {
        noSerialize: true,
      })
      .then(() => {
        setSuccess(true);
        setSubmitting(false);
        setIsSubmitting(false);
      })
      .catch(error => {
        setSuccess(false);
        setSubmitting(false);
        setIsSubmitting(false);
        if (error?.response) {
          setErrors(getResponseErrors(error));
        }
      });
  }

  return (
    <Container>
      <Seo
        title={messages.forgotPasswordSeoTitle}
        description={messages.forgotPasswordSeoDescription}
      />
      <Main className="m-auto sm:max-w-740">
        <Formik
          initialValues={{}}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isValid }) => (
            <Form>
              <Card onlyDesktop>
                <StepTitle className={'sm:text-center'}>
                  {formatMessage(messages.forgotPasswordTitle)}
                </StepTitle>
                <Card onlyMobile>
                  <p className="mb-40">
                    {formatMessage(messages.forgotPasswordInfo)}
                  </p>
                  <div className="fieldset sm:flex-row">
                    <Input
                      name="email"
                      label={formatMessage(messages.forgotPasswordEmailLabel)}
                      full
                      placeholder={formatMessage(messages.placeholderEmail)}
                    />
                  </div>
                </Card>
                <div className="flex m-auto flex-col align-center items-center justify-center mx-8 sm:mx-0">
                  {success ? (
                    <Alert level="success" className="w-full my-20">
                      {formatMessage(messages.forgotPasswordEmailSent)}
                    </Alert>
                  ) : errors?.length > 0 ? (
                    errors.map((error, index) => (
                      <Alert
                        // eslint-disable-next-line
                        key={`error-${index}`}
                        level="danger"
                        className="w-full my-20"
                      >
                        {error}
                      </Alert>
                    ))
                  ) : null}
                  <Button
                    theme="primary"
                    className="mt-25 sm:w-auto"
                    isSubmitting={isSubmitting}
                    disabled={!isValid}
                  >
                    {formatMessage(messages.forgotPasswordSendLinkButton)}
                  </Button>
                </div>
              </Card>
            </Form>
          )}
        </Formik>
      </Main>
    </Container>
  );
};

export default ForgotPassword;
